@import url('https://fonts.googleapis.com/css2?family=Alata&family=Cabin:ital,wght@0,400..700;1,400..700&family=Encode+Sans:wght@100..900&family=Lexend+Deca:wght@100..900&family=Noto+Kufi+Arabic:wght@100..900&family=Noto+Sans+Thai:wght@100..900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&family=Ysabeau:wght@300;400;500;600;700;800;900;1000&display=swap");


.home-font{
    font-family: "Alata", sans-serif;
 }

 .home-font2{
    font-family: "Ysabeau", sans-serif;
 }

 .home-font-colour{
    color: #cf3aa2;
 }

 .bg-color{
    background-color: #cf3aa2;
 }

 .transform{
    -webkit-transform: perspective(300px) rotateX(30deg);
    -o-transform: perspective(300px) rotateX(30deg);
    -moz-transform: perspective(300px) rotateX(30deg);
    -webkit-transform-origin: 100% 50%;
    -moz-transform-origin: 100% 50%;
    -o-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
 }
 
 .transform2{
    animation: shadowChange 1s infinite 1.6s linear;
 }
 
 @keyframes shadowChange {
    0% {
        width: 168px;
    }
    50% {
        width: 188px;
    }
    100% {
        width: 168px;
    }
}